<template>

  <v-btn v-if="visible"
         text class="z-btn" @click="form_donate=true">
    <div style="font-size: 8px">{{ user.wallet.name }}</div>
    <v-divider></v-divider>
    <div>{{ user.wallet.balance }}฿</div>

    <form-donate v-model="form_donate"/>

  </v-btn>

</template>

<script>

export default {
  data: () => ({
    visible: true,
    form_donate: false,
    user: {wallet: {balance: 0}},
  }),
  created() {
    this.initialize()
  },
  mounted: function () {
    this.$bus.on("shop-balance-update", this.initialize);
    this.$bus.on("reloadPage", this.initialize);
  },
  beforeDestroy: function () {
    this.$bus.off("shop-balance-update", this.initialize);
  },
  methods: {
    initialize() {
      if (this.$store.state.project.permission['shop.user'].status !== 'on') {
        this.visible = false
        return
        // if (this.$store.state.project.permission[route.meta.menu.project].status !== 'hide')
        //   route.meta.menu.permission = this.$store.state.project.permission[route.meta.menu.project]
        // else route.meta.menu.permission = null
      }
      this.visible = true
      this.$axios.get(this.$url.shop_user_info)
          .then(response => {
            this.user = response.data
            // window.console.log(this.user)
          })
    }
  },
  components: {
    "form-donate": () => import('./balance-form-donate'),
  }
}
</script>

<style>

.v-btn.z-btn span {
  display: block;
  color: #bdbdbd;
}

</style>